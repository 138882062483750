import React from "react";
import Layout from "../components/Layout";

const CommonPage = () => {
    return (
        <>
            <Layout addBodyClass="template-cms">
                <div className="container">
                    <div className="custom-row d-flex row-2 mb-40">
                        <div className="grid">
                            <p className="extra-small-text font-light-color font-bold font-capital text-underline">Display</p>
                            <h1 className="section-title">Display title 1</h1>
                            <h3 className="font-weight-500 mb-40">Display extra large text</h3>
                            <p className="extra-small-text font-light-color font-bold font-capital text-underline">Headings</p>
                            <h1>H1 Heading</h1>
                            <h2>H2 Heading</h2>
                            <h3>H3 Heading</h3>
                            <h4>H4 Heading</h4>
                            <h5>H5 Heading</h5>
                            <h6>H6 Heading</h6>
                        </div>
                        <div className="grid">
                            <p className="extra-small-text font-light-color font-bold font-capital text-underline">Body Para - Default</p>
                            <p>Organize and create content with headless CMS, design landing pages, product pages or entire sites with visual and no-code designer and unlock the power of content collaborate</p>
                            <p className="extra-small-text font-light-color font-bold font-capital text-underline">Body para - Small</p>
                            <p className="small-text">Design beautiful content, web pages and experiences without depending on developers.</p>
                            <p className="extra-small-text font-light-color font-bold font-capital text-underline">Body para - Default Medium</p>
                            <p className="font-medium font-dark-color">Intutive Content Editor</p>
                            <p className="extra-small-text font-light-color font-bold font-capital text-underline">Body para - Small Medium</p>
                            <p className="small-text font-medium font-dark-color">Drag-n-Drop Jounrey Builder</p>
                            <p className="extra-small-text font-light-color font-bold font-capital text-underline">All Caps</p>
                            <p className="small-text font-capital font-light-color">POPULAR FEATURES</p>
                            <p className="small-text link-color font-capital">THE BIGGEST BARRIER TO BUSINESS GROWTH</p>
                        </div>
                    </div>

                    <div className="custom-row d-flex">
                        <div className="grid">
                        <p className="extra-small-text font-light-color font-bold font-capital text-underline">Button Style</p>
                            <p>
                                <a className="button" href="#">Primary Button</a>
                                <a className="button left-arrow" href="#">Primary Button Left Arrow</a>
                                <a className="button right-arrow" href="#">Primary Button Right Arrow</a>
                                <a className="button button-small" href="#">Primary Button Small</a>
                            </p>
                            <p>
                                <a className="button button-secondary" href="#">Secondary Button</a>
                                <a className="button button-secondary left-arrow" href="#">Secondary Button Left Arrow</a>
                                <a className="button button-secondary right-arrow" href="#">Secondary Button Right Arrow</a>
                                <a className="button button-secondary button-small" href="#">Secondary Button Small</a>
                            </p>

                            <p>
                                <a className="button black-button" href="">Button Black</a>
                                <a className="button black-button button-small" href="">Button Black</a>
                            </p>

                            <p>
                                <a className="btn-link" href="#">Link Button</a>
                            </p>
                            <p>
                                <a className="btn-link left-arrow" href="#">Link Button Left Arrow</a>
                            </p>
                            <p>
                                <a className="btn-link right-arrow" href="#">Link Button Right Arrow</a>
                            </p>
                        </div>
                    </div>
                    
                </div>
            </Layout>
        </>
    );
}

export default CommonPage;